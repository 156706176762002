<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="newsPlace">
    <!-- Start: Search Header -->
    <div class="flex flex-wrap items-center flex-grow" style="margin-bottom: 10px">
      <div style="margin-left: 10px">
        <vs-input v-on:keyup.enter="searchNewsList()" class="inputx" placeholder="기사 검색" v-model="searchVar.filterWords"/>
      </div>
      <div class="vs-table--search" style="margin: 0px 15px">
        <vs-button
          ref="loadableButton"
          id="button-with-loading"
          color="primary"
          type="border"
          class="p-2 cursor-pointer vs-con-loading__container"
          @click="searchNewsList()"
        >검색</vs-button>
      </div>
    </div>
    <!-- End: Search Header -->
    
    <!-- List 1 -->
    <div class="vx-row">
      <div class="vx-col w-1/2">
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
        <vs-list id="newsList" ref="newsList">
          <vs-list-header title="후보리스트" color="primary"></vs-list-header>
          <draggable v-if="windowWidth > 1050" @change="newsListUpdate" :list="newsList.slice((currentx-1)*itemsPerPage, (currentx)*itemsPerPage)" group="newsList" class="p-2 cursor-move">
            <vs-list-item :style="[(listItem.news_grade == $route.query.category * 100 + 11 || listItem.pastMain == 88) ? {'background-color': '#EEEEFF' } : 
                                  (listItem.news_grade == $route.query.category * 100 + 12 && listItem.sort < 4 ? {'background-color': '#EEFFEE' } : {})]" 
              v-for="(listItem, index) in newsList.slice((currentx-1)*itemsPerPage, (currentx)*itemsPerPage)" :key="index" :title="makeTitle(listItem.title, listItem.viewed, listItem.sort)" :subtitle="listItem.id + ' | ' + listItem.d_pass">
              <span v-html="listItem.writer" />
              <vs-avatar v-if="$route.query.category == 0 || $route.query.category == 100" @click="removeHubo(listItem.id, listItem.title)" slot="avatar" :src="'//cdnimage.dailian.co.kr/images/trash_icon_for_news_hubo.png'" />
              <vs-avatar @click="newsview(listItem.id, 0)" slot="avatar" :src="imgPath + listItem.icon" />
            </vs-list-item>
          </draggable>
          <vs-list-item :style="[(listItem.news_grade == $route.query.category * 100 + 11 || listItem.pastMain == 88) ? {'background-color': '#EEEEFF' } : 
                                  (listItem.news_grade == $route.query.category * 100 + 12 && listItem.sort < 4 ? {'background-color': '#EEFFEE' } : {})]" 
            v-else v-for="(listItem, index) in newsList.slice((currentx-1)*itemsPerPage, (currentx)*itemsPerPage)" :key="index" :title="makeTitle(listItem.title, listItem.viewed, listItem.sort)" :subtitle="listItem.id + ' | ' + listItem.d_pass">
            <span v-html="listItem.writer" />
            <vs-avatar v-if="$route.query.category == 0|| $route.query.category == 100" @click="removeHubo(listItem.id, listItem.title)" slot="avatar" :src="'//cdnimage.dailian.co.kr/images/trash_icon_for_news_hubo.png'" />
            <vs-avatar @click="newsview(listItem.id, 0)" slot="avatar" :src="imgPath + listItem.icon" />
          </vs-list-item>
        </vs-list>
        <div>
            <vs-pagination :total="numOfPages" v-model="currentx"></vs-pagination>
        </div>
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
      </div>
      <div class="vx-col w-1/2">
        <vs-button v-if="$route.query.category == 99" class="p-2 cursor-pointer vs-con-loading__container" :disabled="sendButtonDisable" color="primary" type="filled" v-on:click="sendDataToServer" >배치 확정</vs-button> 
        <vs-button v-else class="p-2 cursor-pointer vs-con-loading__container" :disabled="sendButtonDisable" color="primary" type="filled" v-on:click="newsPlacePreview()" >미리보기 (배치 확정)</vs-button> 
        <vs-list id="topList" ref="topList">
          <vs-button class="p-2 cursor-pointer float-right" color="primary" type="border" v-on:click="clearTopList()" >헤드라인 비우기</vs-button> 
          <vs-list-header :title="'헤드라인: ' + topList.length + '/' + numOfTop" color="primary"></vs-list-header>
          <draggable v-if="windowWidth > 1050" :list="topList" group="newsList" class="p-2 cursor-move" @change="mainTopChange" style="heigth: 500px;">
            <vs-list-item :style="[(listItem.news_grade == $route.query.category * 100 + 11 || listItem.pastMain == 88) ? {'background-color': '#EEEEFF' } : 
                                  (listItem.news_grade == $route.query.category * 100 + 12 && listItem.sort < 4 ? {'background-color': '#EEFFEE' } : {})]" 
              v-for="(listItem, index) in topList" :key="index" :title="makeTitle(listItem.title, listItem.viewed, listItem.sort)" :subtitle="listItem.id + ' | ' + listItem.d_pass">
              <span v-html="listItem.writer" />
              <vs-avatar @click="newsview(listItem.id, 1)" slot="avatar" :src="imgPath + listItem.icon" />
            </vs-list-item>
          </draggable>
          <vs-list-item :style="[(listItem.news_grade == $route.query.category * 100 + 11 || listItem.pastMain == 88) ? {'background-color': '#EEEEFF' } : 
                                  (listItem.news_grade == $route.query.category * 100 + 12 && listItem.sort < 4 ? {'background-color': '#EEFFEE' } : {})]" 
            v-else v-for="(listItem, index) in topList" :key="index" :title="makeTitle(listItem.title, listItem.viewed, listItem.sort)" :subtitle="listItem.id + ' | ' + listItem.d_pass">
            <span v-html="listItem.writer" />
            <vs-avatar @click="topListOrder(index, 'up')" slot="avatar" :src="'//cdnimage.dailian.co.kr/images/newsPlaceArrowUp.png'" />
            <vs-avatar @click="topListOrder(index, 'down')" slot="avatar" :src="'//cdnimage.dailian.co.kr/images/newsPlaceArrowDown.png'" />
            <vs-avatar @click="newsview(listItem.id, 1)" slot="avatar" :src="imgPath + listItem.icon" />
          </vs-list-item>
        </vs-list>
          
        <vs-list id="subTopList" ref="subTopList" v-if="numOfSubTop">
          <vs-button class="p-2 cursor-pointer float-right" color="primary" type="border" v-on:click="clearSubTopList()" >중탑 비우기</vs-button>
          <vs-list-header :title="'중탑: ' + subTopList.length + '/' + numOfSubTop" color="primary"></vs-list-header>
          <draggable v-if="windowWidth > 1050" :list="subTopList" group="newsList" class="p-2 cursor-move" @change="topListChange">
            <vs-list-item :style="[(index < 3 ? {'background-color': '#EEFFEE' } : {})]" 
              v-for="(listItem, index) in subTopList" :key="index" :title="makeTitle(listItem.title, listItem.viewed, listItem.sort)" :subtitle="listItem.id + ' | ' + listItem.d_pass">
              <span v-html="listItem.writer" />
              <vs-avatar @click="newsview(listItem.id, 2)" slot="avatar" :src="imgPath + listItem.icon" />
            </vs-list-item>
          </draggable>
          <vs-list-item :style="[(index < 3 ? {'background-color': '#EEFFEE' } : {})]" 
            v-else v-for="(listItem, index) in subTopList" :key="index" :title="makeTitle(listItem.title, listItem.viewed, listItem.sort)" :subtitle="listItem.id + ' | ' + listItem.d_pass">
            <span v-html="listItem.writer" />
            <vs-avatar @click="subTopListOrder(index, 'up')" slot="avatar" :src="'//cdnimage.dailian.co.kr/images/newsPlaceArrowUp.png'" />
            <vs-avatar @click="subTopListOrder(index, 'down')" slot="avatar" :src="'//cdnimage.dailian.co.kr/images/newsPlaceArrowDown.png'" />
            <vs-avatar @click="newsview(listItem.id, 2)" slot="avatar" :src="imgPath + listItem.icon" />
          </vs-list-item>
        </vs-list>
      </div>

      <!-- Start: 뉴스 상세보기 팝업 -->
      <div>
        <vs-popup ref="newsPlace-preview" classContent="newsPlace-preview" title="배치 미리보기" :active.sync="activePromptPreview">
          <div class="items-center">
            <div class="vx-row mb-6" style="margin:0 auto; position: sticky; top: 0; z-index: 100;">
              <div style="margin:0 auto;">
                <vs-button vs-type="gradient" color="success" icon="create" @click="sendDataToServer">배치 확정</vs-button>
              </div>
            </div>
            <div style="padding-bottom: 20px;" v-html="newsstand"></div>
            <div v-if="numOfTop > 0">
              <div v-if="searchVar.category1 == 0">
                <div class="headLineBox" style="position: relative;" :key="index" v-for="(data, index) in topList">
                  <!-- <div style="width: 106px; height: 98px; background-color: #002ADD; border-radius: 0 0 10px 0; position: absolute; top: 0px; left: 0px; text-align: center; vertical-align: middle;">
                    <p style="color: white; font-size: 32px; line-height: 36px; font-weight: 500; letter-spacing: -2px; margin-top: 10px;">HEAD</p>
                    <p style="color: white; font-size: 32px; line-height: 36px; font-weight: 500; letter-spacing: -2px; margin-top: 4px; ">LINE</p>
                  </div> -->
                  <img class="headLineImg" :src="imgPath + data.icon2"/>
                  <div class="label">Headline</div>
                  <h1 class="headLineTitle">{{data.title}}</h1>
                </div>
              </div>
              <div v-else>
                <div class="headLineBox" style="position: relative;" :key="index" v-for="(data, index) in topList">
                  <img class="headLineImg" :src="imgPath + data.icon2"/>
                  <h1 class="mainTitle">{{data.title}}</h1>
                  <p class="mainSubTitle" v-html="data.sub_title ? data.sub_title.replace(/<\/p><p>/g, '<br>').replace(/<\/?p>/g, '') : ''" ></p>
                </div>
              </div>
            </div>
          </div>
        </vs-popup>
      </div>
      <!-- End: 뉴스 상세보기 팝업 -->

      <!-- Start: 뉴스 상세보기 팝업 -->
      <div class="news-detail-form">
        <vs-popup ref="popup-newsDetail" classContent="popup-newsDetail" title="기사 상세" :active.sync="newsDetailModalActive">
          <div class="items-center">
            <div class="vx-row mb-6" style="margin:0 auto; position: sticky; top: 0;">
            <div style="margin:0 auto;">
              <vs-button vs-type="gradient" color="success" @click="editNews(selectedId)">기사수정</vs-button>
              <vs-button v-if="placeButton == 0 || placeButton == 2" vs-type="gradient" color="primary" @click="setNewsPlace(selectedId, 1)">헤드라인 배치</vs-button>
              <vs-button v-if="(placeButton == 0 || placeButton == 1) && numOfSubTop > 0" vs-type="gradient" color="primary" @click="setNewsPlace(selectedId, 2)">중탑 배치</vs-button>
              <vs-button v-if="placeButton == 1 || placeButton == 2" vs-type="gradient" color="primary" @click="setNewsPlace(selectedId, 0)">배치 제외</vs-button>
            </div>
              <vs-button v-if="activeUserInfo.level == 9" vs-type="flat" color="danger" icon="delete_sweep" @click="deleteNews(selectedId)">기사삭제</vs-button>
            </div>
            <div class="news-detail" id="view_con" style="margin: 20px 0px 0px;" v-html="newsDetail.data"></div>
          </div>
        </vs-popup>
      </div>
      <!-- End: 뉴스 상세보기 팝업 -->
      <!-- <vs-prompt
        class="newsPlace-preview"
        vs-title="배치 미리보기"
        vs-accept-text= "배치확정"
        vs-cancel-text = "취소"
        vs-button-cancel = "border"
        @vs-accept="sendDataToServer"
        :vs-active.sync="activePromptPreview">
        <div class="my-4">
          <div class="thumbnail-preview" :data="data" :key="index" v-for="(data, index) in topList">
            <img :src="imgPath + data.icon"/>
          </div>
        </div>
      </vs-prompt> -->
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import moment from 'moment';

export default {
  components: {
    draggable,
  },
  data() {
    return {
      imgPath: 'https://cdnimage.ebn.co.kr/news/',
      windowWidth: window.innerWidth,
      ranges: { //default value for ranges object (if you set this to false ranges will no be rendered)
          '오늘': [moment(), moment()],
          '1주일': [moment().subtract(1, 'week'), moment()],
          '한달': [moment().subtract(1, 'month'), moment()],
          '이번 달': [moment().startOf('month'), moment().endOf('month')],
          '지난 달': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      },
      dateRange: { // used for v-model prop
        startDate: moment().subtract(1, 'month'),
        endDate: moment(),
      },
      opens: "center",//which way the picker opens, default "center", can be "left"/"right"
      locale: {
          direction: 'ltr', //direction of text
          format: 'YYYY-MM-DD', //fomart of the dates displayed
          separator: ' - ', //separator between the two ranges
          applyLabel: '선택',
          cancelLabel: '취소',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          "daysOfWeek": [
              "일",
              "월",
              "화",
              "수",
              "목",
              "금",
              "토"
          ],
          "monthNames": [
              "1월",
              "2월",
              "3월",
              "4월",
              "5월",
              "6월",
              "7월",
              "8월",
              "9월",
              "10월",
              "11월",
              "12월"
          ],
          firstDay: 0, //ISO first day of week - see moment documenations for details
          showISOWeekNumbers: true //show week numbers on each row of the calendar
      },
      options: {
        sorts: [
          '최신+조회 순',
          '최신 순',
          '조회 순',
        ],
        subjects: [
          '제목+본문',
          '제목',
          '본문'
        ]
      },
      subject: '제목+본문',
      sortBy: '최신+조회 순',
      selectedId: '',
      selected: [],
      newsList: [],
      topList: [],
      subTopList: [],
      newsstand: '',
      newsDetail: '',
      numOfTop: 6,
      numOfSubTop: 18,
      numOfNews: 0,
      numOfPages: 0,
      itemsPerPage: 20,
      currentx: 1,
      isMounted: false,
      activePromptPreview: false,
      newsDetailModalActive: false,
      placeButton: 0,
      sendButtonDisable: true,
      seriesArray: [],
      preview: '',
      dailyTopic: {
        selected: null,
        image: '',
        title: '',
      },
      searchVar: {
        state: '1',
        category1: '0',
        startDate: moment().subtract(1, 'month'),
        endDate: moment(),
        filterWords: '',
        sortBy: 'published_time',
        subject: 'title_and_description'
      },
      files: [],
      uploadPercentage: 0,
    }
  },
  beforeRouteLeave (to, from, next) {
    this.newsDetailModalActive = false;
    setTimeout(() => { next(); }, 100);
  },
  watch: {
    '$route.query': function() {
      console.log(this.$route.query);
      this.newsDetailModalActive = false;
      this.created();
    },
    currentx: function() {
      this.scrollToTop()
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    // PROFILE
    activeUserInfo() {
        return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    scrollToTop() {
        window.scrollTo(0,0)
    },
    searchNewsList() {
      
      this.searchVar.startDate = this.dateRange.startDate
      this.searchVar.endDate = this.dateRange.endDate

      this.searchVar.sortBy = this.fixSortBy(this.sortBy)
      this.searchVar.subject = this.fixSubject(this.subject)

      this.created()
    },
    fixSortBy(sortBy) {
      
      if(this.options.sorts[0] == sortBy) {

        return 'published_time_before_view'
      }
      else if(this.options.sorts[1] == sortBy) {
        
        return 'published_time'
      }
      else if(this.options.sorts[2] == sortBy) {

        return 'view'
      }
      else {

        return ''
      }
    },
    fixSubject(subject) {

      if(this.options.subjects[0] == subject) {

        return 'title_and_description'
      }
      else if(this.options.subjects[1] == subject) {

        return 'title'
      }
      else if(this.options.subjects[2] == subject) {

        return 'description'
      }
      else {

        return ''
      }
    },
    newsview(id, placeButton){
      console.log('event: ',id);
      this.placeButton = placeButton;
      this.selectedId = id;
      this.newsDetailModalActive = true;
      this.newsDetail = '';
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });
      this.$http.post('/api/getSingleNews/detail', {id: id})
        .then(function (response) {
          console.log(response.data);
          thisIns.newsDetail = response.data;
          thisIns.$vs.loading.close();
          // var images = response.data[1];
          // for(var i=0;i<images.length;i++){
          //   thisIns.sendData.arrayBase64.push(images[i].img);
          //   thisIns.sendData.arrayImageText.push(images[i].img_c);
          //   thisIns.sendData.arrayImageAlign.push(images[i].align);
          //   thisIns.sendData.arrayImageWidth.push(images[i].width);
          // }
          // var tags = response.data[2];
          // for(var i=0;i<tags.length;i++){
          //   thisIns.sendData.tag.push(tags[i].tag);
          // }
        })
        .catch(function (error) {
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    removeHubo(id, newsTitle) {
      var thisIns = this

      console.log('id:', id, ', newsTitle:', newsTitle)

      thisIns.$vs.dialog({
        type: 'confirm',
        color: 'red',
        title: `후보 삭제`,
        text: `후보 '${newsTitle}'를(을) 삭제하시겠습니까?`,
        accept: function() {

          thisIns.$http.post('/api/updateHuboNews', {id: id, register: false})
            .then(function (response) {

                console.log(response)

                for(var idx in thisIns.newsList) {
                  
                  if(thisIns.newsList[idx].id == id) {

                    thisIns.newsList.splice(thisIns.newsList.indexOf(thisIns.newsList[idx]), 1)

                    thisIns.$vs.notify({
                      title:'후보 삭제 완료',
                      text: `'${newsTitle}'`,
                      color:'primary',
                      iconPack: 'feather',
                      icon:'icon-alert-circle'})

                    break
                  }
                }
            })
            .catch(function (error) {
              
                thisIns.$vs.notify({
                  title:'Error',
                  text: error,
                  color:'danger',
                  iconPack: 'feather',
                  icon:'icon-alert-circle'})
                thisIns.$vs.loading.close();  
            });
        }
      })
    },
    setNewsPlace(newsID, placeButton){
      if(this.placeButton == 0){
        let tmpIdx = this.newsList.findIndex(e => e.id == newsID);
        if(placeButton == 1){ this.topList = [this.newsList[tmpIdx]].concat(this.topList) }
        else if(placeButton == 2){ this.subTopList = [this.newsList[tmpIdx]].concat(this.subTopList) }
        this.newsList.splice(tmpIdx, 1)
        this.newsListUpdate('');
      }else if(this.placeButton == 1){
        let tmpIdx = this.topList.findIndex(e => e.id == newsID);
        if(placeButton == 0){ this.newsList.push(this.topList[tmpIdx]); this.newsListUpdate('');}
        else if(placeButton == 2){ this.subTopList = [this.topList[tmpIdx]].concat(this.subTopList) }
        this.topList.splice(tmpIdx, 1)
      }else if(this.placeButton == 2){
        let tmpIdx = this.subTopList.findIndex(e => e.id == newsID);
        if(placeButton == 0){ this.newsList.push(this.subTopList[tmpIdx]); this.newsListUpdate('');}
        else if(placeButton == 1){ this.topList = [this.subTopList[tmpIdx]].concat(this.topList) }
        this.subTopList.splice(tmpIdx, 1)
      }
      this.topListChange();
      this.newsDetailModalActive = false;
    },
    topListOrder(index, method){
      let tmpList = {};
      if(method == 'up' && index > 0){
        tmpList = this.topList[index];
        this.topList[index] = this.topList[index - 1];
        this.topList[index - 1] = tmpList;
      }
      else if(method == 'down' && index < this.numOfTop - 1){
        tmpList = this.topList[index];
        this.topList[index] = this.topList[index + 1];
        this.topList[index + 1] = tmpList;
      }
      this.topList = this.topList.slice();
      this.topListChange();
      console.log(this.topList)
    },
    subTopListOrder(index, method){
      let tmpList = {};
      if(method == 'up' && index > 0){
        tmpList = this.subTopList[index];
        this.subTopList[index] = this.subTopList[index - 1];
        this.subTopList[index - 1] = tmpList;
      }
      else if(method == 'down' && index < this.numOfSubTop - 1){
        tmpList = this.subTopList[index];
        this.subTopList[index] = this.subTopList[index + 1];
        this.subTopList[index + 1] = tmpList;
      }
      this.subTopList = this.subTopList.slice();
      this.topListChange();
      console.log(this.subTopList)
    },
    editNews(newsId){
      console.log("edit: " + newsId);
      this.newsDetailModalActive = false;
      this.$router.push({path: '/writeArticles', query: { id: newsId}});
    },
    newsPlacePreview(){
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/newsPlace/preview', [this.topList, this.subTopList, this.searchVar.category1])
        .then(function (response) {
          console.log(response)
          if(thisIns.numOfTop > 0){
            for(var i in thisIns.topList){
              thisIns.topList[i].icon2 = response.data.newThumbnail[i];
            }
          }
          console.log(thisIns.topList)
          thisIns.newsstand = response.data.newsstand;
          thisIns.activePromptPreview = true;
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    setDailyTopic(dailyTopic){
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/newsPlace/setDailyTopic', dailyTopic)
        .then(function () {

          thisIns.$vs.notify({
            title:'성공',
            text: '배치 확정 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.sendButtonDisable = true;
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
      
    },
    sendDataToServer(){
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/newsPlace/set', [this.topList, this.subTopList, this.searchVar.category1])
        .then(function () {

          thisIns.$vs.notify({
            title:'성공',
            text: '배치 확정 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.activePromptPreview = false;
          thisIns.sendButtonDisable = true;
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    makeTitle(subject, viewed, sort){
      let title = this.decodeEntities(subject)//.split('&lt;').join('<').split('&gt;').join('>')
                   + ' | ' + viewed;
      if(sort) title = sort + ': ' + title;
      return title;
    },
    decodeEntities(str) {
      let element = document.createElement('div');
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
      element.innerHTML = str;
      str = element.textContent;
      return str;
    },
    clearTopList(){
      // console.log(this.$refs["topList"], this.topList);
      this.newsList = this.newsList.concat(this.topList);
      this.topList = [];
      this.$refs["topList"].$el.style.backgroundColor = "";
      this.newsListUpdate('');
    },
    clearSubTopList(){
      this.newsList = this.newsList.concat(this.subTopList);
      this.subTopList = [];
      this.$refs["subTopList"].$el.style.backgroundColor = "";
      this.newsListUpdate('');
    },
    newsListUpdate(e){
      // console.log(e, this.newsList);
      if(e.removed) this.newsList.splice(e.removed.oldIndex + (this.currentx-1)*this.itemsPerPage, 1);
      if(e.added) this.newsList.splice(e.added.newIndex + (this.currentx-1)*this.itemsPerPage, 0, e.added.element);
      this.newsList.sort(function(a, b) {
        return b.d_pass2 - a.d_pass2;
      });
    },
    mainTopChange(e){
      console.log(this.topList)
      if(e.added){
        console.log('icon: ', e.added.element.icon)
        if(e.added.element.icon == '' && this.$route.query.category != 99){
          alert('그림이 없는 기사는 헤드라인에 배치될 수 없습니다.')
          this.topList = this.topList.filter(top => top.id != e.added.element.id);
          this.newsList = this.newsList.concat([e.added.element]);
          this.newsListUpdate('');
        }
      }
      this.topListChange();
    },
    topListChange(){
      // console.log(e, this.topList.length, this.subTopList.length)
      if(this.topList.length == this.numOfTop && this.subTopList.length == this.numOfSubTop){
        this.sendButtonDisable = false;
      }else if(this.numOfTop < 0) this.sendButtonDisable = false;
      else this.sendButtonDisable = true;
      if(this.topList.length == this.numOfTop){
        // console.log(this.$refs["topList"].$el.style.borderColor);
        this.$refs["topList"].$el.style.backgroundColor = "rgba(100, 100, 255, 0.2)";
      }else this.$refs["topList"].$el.style.backgroundColor = "";
      if(this.numOfSubTop){
        if(this.subTopList.length == this.numOfSubTop){
          this.$refs["subTopList"].$el.style.backgroundColor = "rgba(100, 255, 100, 0.2)";
        }else this.$refs["subTopList"].$el.style.backgroundColor = "";
      }
    },
    addFile(e){
      // console.log(e.target.files, e.target.files[0]);
      if(e.target.files.length ){
        this.files[0] = e.target.files[0];
        this.submitFiles(e.target.files);
      }
    },
    /*
      Determines if the drag and drop functionality is in the
      window
    */
    determineDragAndDropCapable(){
      /*
        Create a test element to see if certain events
        are present that let us do drag and drop.
      */
      var div = document.createElement('div');

      /*
        Check to see if the `draggable` event is in the element
        or the `ondragstart` and `ondrop` events are in the element. If
        they are, then we have what we need for dragging and dropping files.

        We also check to see if the window has `FormData` and `FileReader` objects
        present so we can do our AJAX uploading
      */
      return ( ( 'draggable' in div )
              || ( 'ondragstart' in div && 'ondrop' in div ) )
              && 'FormData' in window
              && 'FileReader' in window;
    },

    /*
      Submits the files to the server
    */
    submitFiles(files){
      /*
        Initialize the form data
      */
      let formData = new FormData();
      let thisIns = this;

      /*
        Iteate over any file sent over appending the files
        to the form data.
      */
      for( var i = 0; i < files.length; i++ ){
        let file = files[i];
        formData.append('file', file);
      }
      /*
        Make the request to the POST /file-drag-drop-instant URL
      */
      this.$http.post( '/api/newsPlace/uploadFile',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          }.bind(this)
        }
      ).then(function(response){
        console.log('SUCCESS!!', response);
        if(response.data.length){
          thisIns.dailyTopic.image = response.data[0];
          thisIns.preview = "/api/photo/viewImages?filename=" + response.data[0];
        }
        // thisIns.getImagePreviews();
      })
      .catch(function(response){
        console.log('FAILURE!!', response);
      });
    },
    created() {
      this.currentx = 1;
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });

      var placeUrl = '' 

      this.dateRange.startDate = moment().subtract(1, 'month')
      this.searchVar.startDate = this.dateRange.startDate
      this.searchVar.category1 = this.$route.query.category || '0';
      if(this.$route.query.category == 0 || this.$route.query.category == 99 || this.$route.query.category == 100){
        if(this.$route.query.category == 0){
          this.numOfTop = 1;
          this.numOfSubTop = 13;
        }
        else if(this.$route.query.category == 99){
          this.numOfTop = -1;
          this.numOfSubTop = 0;
        }
        else if(this.$route.query.category == 100){
          this.numOfTop = 1;
          this.numOfSubTop = 9;
        }
        placeUrl = '/api/getNewsList/placeMain';
      }
      // else if(this.$route.query.category == 6 || this.$route.query.category == 7 || this.$route.query.category == 9) {
      //   placeUrl = '/api/getNewsList/place'        
      //   this.numOfTop = 1;
      //   this.numOfSubTop = 5;
      // }
      else {
        placeUrl = '/api/getNewsList/place'        
        this.numOfTop = 1;
        this.numOfSubTop = 3;
      }
      console.log('placeUrl:', placeUrl)
      this.topList = [];
      this.subTopList = [];
      this.sendButtonDisable = true;
      if(this.numOfTop < 0) this.sendButtonDisable = false;

      this.$http.post(placeUrl, this.searchVar)
        .then(function (response) {
          thisIns.numOfNews = response.data[0][0].news_count - thisIns.numOfTop - thisIns.numOfSubTop;
          thisIns.numOfPages = Math.ceil(thisIns.numOfNews / thisIns.itemsPerPage)
          thisIns.newsList = response.data[1];
          console.log('response.data:', response.data, thisIns.numOfPages)
          console.log('response:', response)

          thisIns.$http.post('/api/newsPlace/get', {category: thisIns.searchVar.category1})
            .then(function (response) {
              let tmpDatas = [];
              tmpDatas = response.data;
              thisIns.topList = tmpDatas.filter(data => data.news_grade == (thisIns.$route.query.category * 100 + 11).toString());
              thisIns.topList.forEach(data =>{
                var tmp = thisIns.newsList.findIndex(news => news.id == data.id)
                // console.log(tmp);
                if(tmp >= 0) thisIns.newsList.splice(tmp, 1);
              });
              // console.log(thisIns.topList);
              if(thisIns.numOfSubTop){
                thisIns.subTopList = tmpDatas.filter(data => data.news_grade == (thisIns.$route.query.category * 100 + 12).toString());
                thisIns.subTopList.forEach(data =>{
                  var tmp = thisIns.newsList.findIndex(news => news.id == data.id)
                  // console.log(tmp);
                  if(tmp >= 0) thisIns.newsList.splice(tmp, 1);
                });
                // console.log(thisIns.subTopList);
              }
              thisIns.$vs.loading.close();
            })
            .catch(function (error) {
              var errorText = error.toString();
              if(errorText.includes('401')){
                alert('로그인 해주세요!')
                thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
              }
              thisIns.$vs.notify({
                title:'Error',
                text: error,
                color:'danger',
                iconPack: 'feather',
                icon:'icon-alert-circle'})
              thisIns.$vs.loading.close();  
            });

            console.log('thisIns.newsList:', thisIns.newsList)
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });  
      if(this.$refs["topList"]){
        this.$refs["topList"].$el.style.backgroundColor = "";
      }
      if(this.$refs["subTopList"]){
        this.$refs["subTopList"].$el.style.backgroundColor = "";
      }
    },
    handleWindowResize(event) {
        this.windowWidth = event.currentTarget.innerWidth;
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },
  created() {
    this.seriesArray = this.$store.state.series;
    this.created();
  },
  mounted() {
    this.$nextTick(() => {
        window.addEventListener('resize', this.handleWindowResize);
    });
    this.isMounted = true;
    if(this.$route.query.category == 0){
      /*
        Determine if drag and drop functionality is capable in the browser
      */
      this.dragAndDropCapable = this.determineDragAndDropCapable();

      /*
        If drag and drop capable, then we continue to bind events to our elements.
      */
      if( this.dragAndDropCapable ){
        /*
          Listen to all of the drag events and bind an event listener to each
          for the fileform.
        */
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function( evt ) {
          /*
            For each event add an event listener that prevents the default action
            (opening the file in the browser) and stop the propagation of the event (so
            no other elements open the file in the browser)
          */
          this.$refs.fileform.addEventListener(evt, function(e){
            e.preventDefault();
            e.stopPropagation();
          }.bind(this), false);
        }.bind(this));

        /*
          Add an event listener for drop to the form
        */
        this.$refs.fileform.addEventListener('drop', function(e){
          /*
            Capture the files from the drop event and add them to our local files
            array.
          */
          this.files[0] = e.dataTransfer.files[0];
          console.log(e.dataTransfer.files);
          this.submitFiles(e.dataTransfer.files);
        }.bind(this));
      }
    }
  }
}
</script>

<style lang="scss">
#newsPlace{
  #topList, #subTopList{
    .cursor-move{
      min-height: 300px;
    }
  }
  #newsList{
    .cursor-move{
      min-height: 800px;
    }
  }
  .dailyTopic{  
    form{
      display: inline-block;
      height: 150px;
      width: 150px;
      background: #ccc;
      text-align: center;
      border-radius: 4px;
      vertical-align: bottom;
    }
    .drop-files{
      p{
        padding-top: 50px;
        height: 100%;
      }
    }
    input[type="file"] { /* 파일 필드 숨기기 */ 
      position: absolute; 
      width: 1px; 
      height: 1px; 
      padding: 0; 
      margin: -1px; 
      overflow: hidden; 
      clip:rect(0,0,0,0); 
      border: 0; 
    }
    progress{
      width: 150px;
      margin: auto;
      display: block;
      margin-top: -20px;
    }
  }
  @media (max-width: 700px) {
    // font-size: 0.7em;
    .vx-row > .vx-col{
      padding: 0 0.2rem;
    }
  }
}
.headLineBox{
  position: relative;
  width: 648px;
  .headLineImg{
    width: 648px;
    height: 330px;
    object-fit: cover;
    object-position: 50% 50%;
  }
  .label{
    position: absolute;
    bottom: 72px;
    left: 50%;
    margin-left: -50px;
    width: 99px;
    height: 29px;
    background-color: #000000;
    font-size: 18px;
    line-height: 29px;
    color: #f7f7f7;
    text-align: center;
  }
  .headLineTitle{
    font-size: 26px;
    line-height: 37px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    padding: 28px 0 20px;
    border-bottom: 1px solid #000000;
  }
  .mainTitle{
    font-size: 28px;
    line-height: 35px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mainSubTitle{
    margin: 15px 0 0;
    font-size: 14px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>